.unauthorized-page-background {
    background-color: #2d2d2d;
}

.unauthorized-page {
    height: calc(100vh - 82px);
}

.protiviti-logo-background {
    filter: brightness(0%);
    opacity: 0.2;
}

.unauthorized-text {
    color: #ffffff;
    font-family: Calibri;
    max-width: 800px;
    padding: 40px 20px 50px 20px;
    font-family: Arial, Helvetica, sans-serif;
}

.protiviti-logo-div {
    text-align: center;
}
