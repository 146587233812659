#tooltip-bottom > .tooltip-inner {
    background-color: #f2f2f2;
    color: #004068;
    border-radius: 6px;
    box-shadow: 0 0.125rem 0.625rem rgba(90, 97, 105, 0.2);
    max-width: none;
}

#tooltip-bottom > .arrow {
    display: none;
}

#tooltip-bottom.tooltip.show {
    opacity: 1 !important;
}

.icon-div {
    display: inline;
    float: left;
    padding-right: 6px;
    padding-top: 3px;
}

.icon-div img {
    width: 55px;
    margin-left: 5px;
}

.text-div {
    display: inline-block;
    vertical-align: top;
    padding-left: 4px;
    padding-top: 3px;
    max-width: 600px;
}

.tooltiptext {
    text-align: left;
    display: table;
    max-width: 700px;
    padding: 5px;
}

.validation-thumb {
    color: #00acc4;
    cursor: pointer;
}

.validation-thumb:hover {
    color: #5d7b9a;
}
