.following-active-account {
    font-size: 12px !important;
    background-color: #ffffff !important;
    border-color: #5d7b9a !important;
    color: #5d7b9a !important;
}

.following-inactive-account {
    font-size: 12px !important;
    background-color: #00acc4 !important;
    border-color: #00acc4 !important;
    color: #ffffff !important;
}

.following-active-account:hover {
    background-color: #00acc4 !important;
    border-color: #00acc4 !important;
    color: #ffffff !important;
}

.following-inactive-account:hover {
    background-color: #ffffff !important;
    border-color: #5d7b9a !important;
    color: #5d7b9a !important;
}

.account-name {
    font-weight: 500;
    color: #5d7b9a;
    font-family: Arial, Helvetica, sans-serif;
    font-style: italic;
}

.account-background {
    margin-top: 20px;
    margin-bottom: 40px;
    padding-left: 30px;
    padding-right: 30px;
}

.modal-header-overflow {
    overflow: auto;
}

.modal-body-class {
    overflow-x: auto;
    padding: 0 0 80px 0 !important;
}

/*.card-title-modal {
    padding: 0 0;
    align-self: center;
}*/

.number-wins-title-modal {
    margin-bottom: 0px;
    padding: 15px 15px 5px 15px;
    text-align: left;
    color: #004068;
    font-family: Arial, Helvetica, sans-serif;
}

.number-wins-dialog {
    max-width: 700px !important;
}

.number-wins-modal-icon {
    padding: 0 0 0 25px;
}

.solution-total {
    padding: 0px 30px 0px 15px;
    font-size: 13px;
    color: gray;
    font-weight: 400;
    width: auto;
    margin-bottom: 20px;
    margin-top: 15px;
}

.solution-total-key {
    font-size: 18px;
    text-align: center;
    color: #004068;
    font-weight: 500;
}

.solution-total-value {
    font-size: 20px;
    text-align: center;
}
